import React, { FC } from 'react'
import Heading from '@ui/components/Heading'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { useUser } from '@app/lib/hooks/useUser'
import MemberNumber from '../MemberNumber'
import SecretaryProfile from '../SecretaryProfile'

const Container = styled.div`
  display: none;
  ${media.desktop`
    display: flex;
    flex-direction: column;
  `}
`

const DesktopDashboardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledH3 = styled.h3`
  margin: 0;
  color: ${props => props.theme.colors.info};
  font-family: ${props => props.theme.fonts.intro};
  font-weight: 900;
  text-transform: uppercase;
  font-size: ${(props: any) => props.theme.fontSizes.smallx};
  ${media.desktop`
    font-size: ${(props: any) => props.theme.fontSizes.medium};
  `}
`

const StyledHr = styled.div`
  border-bottom: 1px solid #C5D9E580;
  margin-top: 1.875rem;
`

const DesktopDashboardHeader: FC = () => {
  const { session } = useUser()

  return (
    <Container>
      <DesktopDashboardHeaderWrapper>
        <div>
          <StyledH3>Welcome back,</StyledH3>
          <Heading headingType="h1" heading={session?.data && `${session.data?.given_name} ${session.data?.family_name}`} />
          <MemberNumber />
        </div>
        <SecretaryProfile />
      </DesktopDashboardHeaderWrapper>
      <StyledHr />
    </Container>
  )
}

export default DesktopDashboardHeader
