import DesktopDashboardHeader from '@app/ui/components/DesktopDashboardHeader'
import { FC, useMemo } from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import DashboardGrid from '@app/ui/components/DashboardGrid'
import GenericCTA from '@app/ui/components/GenericCTA'
import pxToRem from '@app/ui/utils/pxToRem'
import AwardAccordion from '@app/ui/components/AwardAccordion'
import IssuesAtWork from '@app/ui/components/IssuesAtWork'
import { IssuesAtWorkProps } from '@app/ui/components/IssuesAtWork/types'
import YourWorkplaceInformation from '@ui/components/YourWorkplaceInformation'
import { PageContent } from '../PageContent'
import { useUser } from '@app/lib/hooks/useUser'
import { useAppSelector } from '@app/store'
import { primaryDashboardCampaign } from '@app/ui/components/DashboardGrid/types'
import { slugToBranchPrefix } from '@app/ui/utils/slugToBranchPrefix'
import { getActiveBranchFromSession } from '@app/lib/utils'
import Head from 'next/head'
import { useTransition } from '@app/lib/hooks/useTransition'
import { LoaderOverlay } from '../LoaderOverlay'
import { useWidgetsLoaded } from '@app/lib/hooks/useWidgetsLoaded'
import { extractAwardAccordionFromRawEbaData, extractAwardIndicatorFromRawEbaData, getEbaData } from '@app/lib/formatters/awards'
import SecretaryProfile from '../SecretaryProfile'
import PrimaryGenericCard from '../PrimaryGenericCard'

const DashboardHeaderSectionWrapper = styled.div`
  width: 100%;
  background-color: #e8f1fa;
  position: relative;
  z-index: 1;
`

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${pxToRem(20)};
  padding-bottom: ${pxToRem(20)};
  padding-left: ${pxToRem(17)};
  padding-right: ${pxToRem(17)};
  ${media.desktop`
    max-width: ${pxToRem(1130)};
    padding: ${pxToRem(40)};
  `}
`

const ThreeColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${pxToRem(15)};
  ${media.desktop`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: ${pxToRem(28)};
  `}
`

const TwoThirdColumnWrapper = styled.div`
  ${media.desktop`
    grid-column: span 2 / span 2;
  `}
`

const OneThirdColumnWrapper = styled.div`
`

const GenericCTAWrapper = styled.div`
`

const DashboardGridWrapper = styled.div`
  ${media.tablet`
    margin-left: ${pxToRem(-14)};
    margin-right: ${pxToRem(-14)};
  `}
`

const SecretaryContainer = styled.div`
  margin-top: 1rem;
  ${media.desktop`display: none;`}
`

export const DashboardPage: FC = () => {
  const { session } = useUser()
  const { transitionState } = useTransition()
  const { widgetsLoaded } = useWidgetsLoaded()
  const { branchSettings } = useAppSelector(state => state.app)

  const {
    employers,
    currentEmployerId,
    payRateData,
  } = useAppSelector(state => state.employers)

  const {
    ebasData,
  } = useAppSelector(state => state.ebas)

  const { siteSettings } = useAppSelector(state => state.app)
  
  const correctEbaData = useMemo(() => getEbaData(employers, currentEmployerId, payRateData, ebasData, siteSettings), [employers, currentEmployerId, payRateData, ebasData, siteSettings])

  const awardIndicator = extractAwardIndicatorFromRawEbaData(correctEbaData)
  const awardAccordion = extractAwardAccordionFromRawEbaData(correctEbaData)

  let reconciledAwardAccordion: any = []
  if(Array.isArray(awardAccordion)) {
    reconciledAwardAccordion = awardAccordion?.filter((item: any) => (item?.bodyHtml) && (item?.bodyHtml?.trim().length > 0))
  }

  const awardFaqHasNoContent = !(reconciledAwardAccordion.length > 0)

  const issuesAtWorkComponent: IssuesAtWorkProps = {
    branch: getActiveBranchFromSession(session),
  }

  return (
    <main style={{ position: 'relative' }}>
      <LoaderOverlay show={transitionState === 'fadeOut' || !widgetsLoaded} />
      <Head>
        <title>SDA Members Area</title>
      </Head>
      <DashboardHeaderSectionWrapper>
        <HeaderContent>
          <DesktopDashboardHeader />
            <YourWorkplaceInformation hideAwardIndicator={awardFaqHasNoContent}/>
            <SecretaryContainer>
              <PrimaryGenericCard hideTitle backgroundColor="#F3F8FC">
                <SecretaryProfile />
              </PrimaryGenericCard>
            </SecretaryContainer>
        </HeaderContent>
      </DashboardHeaderSectionWrapper>
      <PageContent>
        <ThreeColumnWrapper>
          <TwoThirdColumnWrapper>
            {!awardFaqHasNoContent &&
              <AwardAccordion awardSummaryLink={awardIndicator?.buttonUrl ?? '#'} accordions={reconciledAwardAccordion} />
            }
            {awardFaqHasNoContent &&
              <YourWorkplaceInformation showOnlyAwardIndicator />
            }
          </TwoThirdColumnWrapper>
          <OneThirdColumnWrapper>
            <IssuesAtWork {...issuesAtWorkComponent} />
          </OneThirdColumnWrapper>
        </ThreeColumnWrapper>
          <GenericCTAWrapper>
            {branchSettings?.primaryWidget?.map((primaryDashboardCampaign: primaryDashboardCampaign, i: number) => {
              return primaryDashboardCampaign.cta ? (
              <GenericCTA
                key={i}
                backgroundImage={primaryDashboardCampaign?.featuredImage?.mediaItemUrl}
                title={primaryDashboardCampaign?.title}
                buttonText={primaryDashboardCampaign.cta?.title}
                buttonUrl={slugToBranchPrefix(primaryDashboardCampaign.cta?.url, primaryDashboardCampaign?.cta?.__typename, session)}
                target={primaryDashboardCampaign.cta?.target}
              />) : null
            }
            )}
          </GenericCTAWrapper>
        <DashboardGridWrapper>
          <DashboardGrid key={session?.data?.activeId} />
        </DashboardGridWrapper>
      </PageContent>
    </main>
  )
}

export default DashboardPage
