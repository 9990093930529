//-----------------
// This component is the 'Your minimum payrate should be:' widget that displays on the dashboard and the 'Your award or agreement' page.
//-----------------

import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import dayjs from 'dayjs'
import Text from '../Text'
import PrimaryGenericCard, { FlexBox } from '@ui/components/PrimaryGenericCard'
import { IPayRate, PayRateIndicatorProps } from './types'
import Modal from '../Modal'
import { media } from 'styled-bootstrap-grid'
import SelectDropdown from '@app/ui/components/SelectDropdown/'
import pxToRem from '@app/ui/utils/pxToRem'
import Button from '@app/ui/components/Button'
import Tabs, { Tab } from '@app/ui/components/Tabs'
import AccordionSection from '@app/ui/components/AccordionSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton from 'react-loading-skeleton'
import { useAppSelector } from '@app/store'
import { cleanDoubleSlashes, getActiveBranchFromSession } from '@app/lib/utils'
import {
  fetchUserDateOfBirth,
  Status,
  setPayRateIndicatorState,
  setEmployStatusSelected,
  setRetailValueSelected,
  setEmploymentLevel,
  pushEmploymentStatusUpdate,
  pushClearEmploymentData,
  clearEmploymentLevel
} from '@app/store/slices/employer-slice'
import { useApp } from '@app/lib/hooks/app'
import { useUser } from '@app/lib/hooks/useUser'
import { extractAwardIndicatorFromRawEbaData, getEbaData } from '@app/lib/formatters/awards'
import { selectAskAQuestionLink } from '@app/store/selectors/selectAskAQuestion'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { EmployerDataLoader } from '@ui/components/EmployerDataLoader'

const loadingSkeleton = <>
  <Skeleton
    count={1}
    width={'100%'}
    height={30}
  />
  <Skeleton
    count={1}
    width={'100%'}
    height={30}
  />
  <Skeleton
    count={1}
    width={'100%'}
    height={30}
  />
  <Skeleton
    count={1}
    width={'100%'}
    height={30}
  />
</>

export const GridItem = styled.div<{
  span?: number
  order?: number
  columnStart?: number
  columnEnd?: number
  $marginTop?: boolean
}>`
  ${props => props.span && `
    grid-column: span ${props.span};
  `}
  ${props => props.order && `
    order: ${props.order};
  `}
  ${props => props.columnStart && `
    grid-column-start: ${props.columnStart};
  `}
  ${props => props.columnEnd && `
    grid-column-end: ${props.columnEnd};
  `}
  ${props => props.$marginTop && `
    margin-top: 1.3em;
  `}
`

const InfoIcon = styled.span`
  background-color: #757575;
  color: #ffffff;
  border-radius: 50%;
  width: ${pxToRem(13)};
  height: ${pxToRem(13)};
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: ${pxToRem(7)};
  transition: all linear 0.2s;
`

const DropdownTitle = styled.span`
  display: inline-flex;
  font-size: ${pxToRem(13)};
  line-height: 1;
`

const ViewAllLink = styled('a')`
  display: inline-flex !important;
  flex-wrap: wrap;
  align-items: center;
  gap: ${pxToRem(5)};
  font-size: ${(props: any) => props.theme.fontSizes.small2x};
  color: #1A4469;
  text-decoration: underline;
  &:hover, &:focus, &:active {
    color: #ff6820;
  }
  svg {
    font-size: ${(props: any) => props.theme.fontSizes.medium};
    color: #ff6820;
  }
`

const InfoText = styled.span<{
  disable?: boolean
}>`
  font-style: italic;
  font-size: ${props => props.theme.fontSizes.smallx};
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  line-height: 1;
  ${props => props.disable && `
    pointer-events: none;
    opacity: 0.5;
  `}
  &:hover {
    color: #012F57;
    ${InfoIcon} {
      background-color: #FF6820
    }
  }
`
const PayRateInfo = styled('div') <{
  isShow?: boolean
}>`
  display: ${(props) => props.isShow ? 'flex' : 'none'};
  gap: ${pxToRem(10)};
  flex-wrap: wrap;
  ${media.desktop`
    flex-direction: column;
  `}
`
const PayRateControl = styled('div') <{
  isShow?: boolean
}>`
  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  &.reset-fade {
    animation: fadein 1s linear forwards;
  }
  display: ${(props) => props.isShow ? 'grid' : 'none'};
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${pxToRem(15)};
  grid-row-gap: ${pxToRem(5)};
  font-size: ${(props: any) => props.theme.fontSizes.smallx};
  color: #4A4A4A;
  input {
    padding: ${pxToRem(8)} ${pxToRem(11)} ${pxToRem(7)} ${pxToRem(10)};
    color: #757575;
  }
  ${Button} {
    padding: ${pxToRem(7)} ${pxToRem(14)};
    font-size: ${pxToRem(13.5)};
    line-height: 1;
    letter-spacing: 0.6px;
  }
  ${media.desktop`
    font-size: ${(props: any) => props.theme.fontSizes.small2x};
  `}
`
const Tag = styled('div')`
  display: flex;
  gap: ${pxToRem(11)};
  align-items: center;
  width: auto;
  border-radius: ${pxToRem(10)};
  padding: ${pxToRem(6)} ${pxToRem(10)};
  background-color: #FFF;
  font-weight: 800;
  line-height: 1;
  width: min-content;
  ${media.desktop`
    padding: ${pxToRem(6)} ${pxToRem(16)};
  `}
  span:first-child {
    white-space: nowrap;
  }
`

const SalaryPopupContent = styled.div`
  border-top: 1px solid;
  border-color: #B7D4E54D;
  padding-top: ${pxToRem(16)};
  font-size: ${(props: any) => props.theme.fontSizes.small2x};
  a {
    color: #00AFD9;
    margin-left: ${pxToRem(6)};
    font-size: ${(props: any) => props.theme.fontSizes.small2x};
  }
  h3 {
    font-size: 0.9rem;
    font-weight: 700;
    margin-bottom: ${pxToRem(8)};
    margin-top: ${pxToRem(6)};
  }
  p {
    padding-bottom: ${pxToRem(10)};
    font-size: ${(props: any) => props.theme.fontSizes.small2x};
  }
  ul {
    margin-left: 0;
    li {
      font-size: ${(props: any) => props.theme.fontSizes.small2x};
      list-style-type: none;
      &:before {
        content: '\\279E';
        color: #00AFD9;
        padding-right: ${pxToRem(12)};
      }
    }
  }
  ${media.desktop`
    border-bottom: 1px solid;
    border-color: #B7D4E54D;
    padding-top: ${pxToRem(24)};
    ul {
      margin-top: ${pxToRem(8)};
      li {
        font-size: ${(props: any) => props.theme.fontSizes.small2x};
        margin-bottom: ${pxToRem(5)};
      }
    }
  `}
`
const ReviewSalaryContainer = styled.div`
  p {
    margin: 0;
    margin-bottom: ${pxToRem(8)};
    display: block;
    font-size: ${(props: any) => props.theme.fontSizes.small2x};
  }
  h3 {
    margin: 0;
    font-size: ${(props: any) => props.theme.fontSizes.small2x};
  }
  a {
    color: #00AFD9;
    text-decoration: underline;
    margin-top: ${pxToRem(4)};
    display: block;
    font-size: ${(props: any) => props.theme.fontSizes.small2x};
  }
  p > a {
    display: inline;
  }
  transition: all linear 0.2s;
`
const MackRequire = styled.span`
  color: #00AFD9;
  margin-left: ${pxToRem(2)};
`

const CardFooter = styled.div`
  margin-top: ${pxToRem(10)};
  line-height: 1.5em;
`

const PleaseUpdateMessage = styled.div`
  font-size: ${pxToRem(14)};
  margin-bottom: ${pxToRem(12)} !important;
  a {
    text-decoration-line: underline;
    cursor: pointer;
  }
`

const StyledNoPayrate = styled.div`
  font-size: ${pxToRem(14)};
  line-height: 1.8;
  
  p {
    font-size: inherit;
    margin-bottom: ${pxToRem(12)};
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ContactLink = styled.a`
  display: inline-block !important;
`

const handleDownloadClick = (event: any) => {
  event.stopPropagation()
  event.nativeEvent.stopImmediatePropagation()
}

const PayRateIndicator: FC<PayRateIndicatorProps> = ({ payRateData }) => {
  const { isMobile } = useApp()
  const { session } = useUser()
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const [showTabsModal, setShowTabsModal] = useState(false)
  const [showSalaryModal, setShowSalaryModal] = useState(false)
  const [showPayRate, setShowPayRate] = useState<true | false>(false)
  const [currentEmployer, setCurrentEmployer] = useState<any | null>(null)
  const askQuestionLink = useAppSelector(selectAskAQuestionLink(session))

  const levelsInfo = [
    { title: 'Level 1', description: '<p>Shop Assistant; Checkout Operator; Nightfiller; Trolley Collector; Video Hire Worker; Telephone Order Salesperson; Door-to-door Salesperson, and, Demonstrator and/or Merchandiser.</p>'},
    { title: 'Level 2', description: '<p>Forklift Operator; Ride on Equipment Operator.</p>'},
    { title: 'Level 3', description: '<p>Supervisor/2IC to Section/Department Manager; Person employed alone, responsibilities for the security and general running of a shop.</p>'},
    { title: 'Level 4', description: '<p>Tradesperson (including, Butcher, Baker, Florist, Pastry Cook); Department Manager with up to 2 employees (including self); Supervisor of up to 4 sales staff; 2IC Shop Manager of a shop without Departments.</p>'},
    { title: 'Level 5', description: '<p>A tradesperson in charge of other tradespersons within a section or department; Service Supervisor (more than 15 employees).</p>'},
    { title: 'Level 6', description: '<p>Assistant Manager of a shop with Departments/Sections; Department Manager with 5 or more employees (including self).</p>'},
    { title: 'Level 7', description: '<p>Visual Merchandiser.</p>'},
    { title: 'Level 8', description: '<p>Store Manager of a shop with Departments/Sections.</p>'},
  ]

  const {
    status,
    payAndConditionsLoadState,
    dateOfBirth,
    employers,
    currentEmployerId,
    payRateIndicatorState,
    employStatusSelected,
    retailValueSelected,
  } = useAppSelector(state => state.employers)

  //---
  // Select the current payrate from the set of payrates
  //---

  let correctPayAndConditionsIndex = -1

  if (Array.isArray(payRateData)) {
    for (let i = 0; i < payRateData.length; i++) {
      if (payRateData[i]?.payAndConditionsParentId === currentEmployer?.payAndConditionsId) {
        correctPayAndConditionsIndex = i
        break
      }
    }
  }

  let currentPayRateData : any = undefined
  if(correctPayAndConditionsIndex !== -1) {
    // @ts-ignore
    currentPayRateData = payRateData[correctPayAndConditionsIndex]?.ratesOfPay?.edges?.map((el: any) => el?.node)
  }

  //---
  // Set the level data based on the current payrate
  //---

  const levelData: string[] = []

  currentPayRateData?.forEach(({ name }: { name: string, ages: any[] }) => {
    levelData.push(name)
  })

  // TODO: Make this good
  levelData.sort(
    (a: string, b: string) => {
      let an = parseInt((a.split(' '))[1]);
      let bn = parseInt((b.split(' '))[1]);

      return (bn < an ? 1 : -1);
    }
  )

  for(let i = 0; i < levelData.length; i++) {
    let niceName = levelData[i]

    if(niceName.includes('(')) {
      const regex = /.*\(/i
      niceName = niceName.replace(regex, '').replace(')', '')
    }

    levelData[i] = niceName
  }

  //---
  // Set the rates based on the current payrate
  //---

  const level = retailValueSelected?.[currentEmployerId] ?? currentEmployer?.workplaceInfo?.level
  const employmentStatus = retailValueSelected?.[currentEmployerId] ? employStatusSelected?.[currentEmployerId] : currentEmployer?.workplaceInfo?.employmentStatus

  const formattedDate = dayjs(dateOfBirth).format()

  const userAgeIsUnknown = (formattedDate === 'Invalid Date')
  let userAge = (userAgeIsUnknown) ? 21 : dayjs().diff(formattedDate, 'years')

  // Logic of line below is as follows:
  // Suppose the level name entered in the payrates app is Level 3 (Team Member)
  // If the name selected in dropdown on frontend is a default level name, e.g. Level 1, Level 2 etc, it will match the first part of the string.
  // If the name selected in dropdown on frontend is custom name, e.g. Team Member, it will match the bracketed part of the string.
  let payLevel = currentPayRateData?.map((rate: any) => ({
    ...rate,
    name: rate.name.match(/\((.*)\)/)?.[1] ?? rate.name,
  })).find((rate: any) => rate.name === level)
  if (!payLevel) {
    if(currentPayRateData && level && status !== Status.LOADING) {
      dispatch(clearEmploymentLevel())
      dispatch(pushClearEmploymentData())
    }
    payLevel = undefined // If the user's level doesn't match any level, the best option is to not display a payrate
  }

  let age = payLevel?.ages?.find((age: any) => (age.minAge <= userAge && age.maxAge >= userAge))

  if(age === undefined && payLevel?.ages?.length > 0 && userAge < payLevel?.ages[0].minAge) {
    age = payLevel?.ages[0] // If the user is below the minimum pay rate age (e.g. they are 14), show the minimum pay rate age
  }

  let payRate : any = undefined
  let tierLabels : any = undefined
  if(age) {
    const payRates = age?.rates?.map((ageRate: any) => {
      const rateDef = ageRate?.types?.find((entry: any) => {
        return entry.type === (employmentStatus?.toLowerCase() === 'casual' ? 'casual' : 'permanent')
      })

      let displayValue = `$${rateDef.manualValue > 0 ? rateDef.manualValue : rateDef.autoValue}`
      if(displayValue?.split('.').length === 1) {
        displayValue += '.00'
      }
      if(displayValue?.split('.')[1].length === 1) {
        displayValue += '0'
      }

      return displayValue
    })

    let customPayrateLabels = []
    const payRateMeta = payLevel?.rates
    if(payRateMeta && Array.isArray(payRateMeta)) {
      customPayrateLabels.push(employmentStatus)
      for(let i = 1; i < payRateMeta.length; i++) {
        customPayrateLabels.push(payRateMeta[i].name)
      }
    }

    if (customPayrateLabels?.length !== 0) tierLabels = customPayrateLabels
    payRate = payRates
  }

  const employStatus: any[] = [
    'Casual',
    'Part Time',
    'Full Time',
    'Salary'
  ]

  useEffect(() => {
    dispatch(setPayRateIndicatorState('prefill-attempt'))
    dispatch(fetchUserDateOfBirth())
  }, [dispatch, session?.data?.activeId])

  useEffect(() => {
    if (currentEmployer && payRateIndicatorState === 'prefill-attempt') {
      const { workplaceInfo } = currentEmployer

      const isSalary = workplaceInfo?.employmentStatus === 'salary'

      if(isSalary) {
        dispatch(setEmployStatusSelected('Salary'))
      }

      setShowPayRate(isSalary || (!isSalary && workplaceInfo?.level && workplaceInfo?.employmentStatus))

      dispatch(setPayRateIndicatorState('settled'))
    }
    if(payRateIndicatorState === 'request-reset') {
      setShowPayRate(false)
      dispatch(setEmployStatusSelected(undefined))
      dispatch(setRetailValueSelected(undefined))
      dispatch(setPayRateIndicatorState('settled'))
    }
    if(payRateIndicatorState === 'request-switch-employer') {
      setShowPayRate(false)
      dispatch(setPayRateIndicatorState('prefill-attempt'))
    }
  }, [
    currentEmployer,
    currentEmployerId,
    dispatch,
    employStatusSelected,
    payRateIndicatorState,
    retailValueSelected,
  ])

  useEffect(() => {
    const newEmployer = employers?.find((element : any) => element.id === currentEmployerId)
    setCurrentEmployer(newEmployer)
  }, [employers, currentEmployerId])

  const isSelectedSalary = () => {
    return employStatusSelected?.[currentEmployerId] === 'Salary'
  }

  const hasSelectedSalary = useMemo(isSelectedSalary, [employStatusSelected, currentEmployerId]);

  const checkSubmitIsDisabled = () => {
    if(hasSelectedSalary) return !employStatusSelected?.[currentEmployerId]
    return !retailValueSelected?.[currentEmployerId] || !employStatusSelected?.[currentEmployerId]
  }

  const submitIsDisabled = useMemo(checkSubmitIsDisabled, [employStatusSelected, retailValueSelected, currentEmployerId, hasSelectedSalary])

  const {
    ebasData,
  } = useAppSelector(state => state.ebas)

  const { siteSettings } = useAppSelector(state => state.app)

  const correctEbaData = useMemo(() => getEbaData(employers, currentEmployerId, payRateData, ebasData, siteSettings), [employers, currentEmployerId, payRateData, ebasData, siteSettings])

  const awardIndicator = extractAwardIndicatorFromRawEbaData(correctEbaData)
  const fullWageTable = awardIndicator?.fullWageTable
  const wageTableOverrideUrl = cleanDoubleSlashes(awardIndicator?.wageTablePdfUrl)
  const hasNoPayrate = useMemo(() => correctEbaData?.noAvailablePayrate ?? false, [correctEbaData])
  const nationalPayrates = [null, '12th25tUB7Da5HWqWv9v', 'aTtRP8gE99UDrtD1Ujjr', 'qzJ449NWvCfhnZwJIrFO'] // National payrates for dev, uat, and prod

  // @ts-ignore
  const currentPayAndConditions = correctPayAndConditionsIndex !== -1 ? payRateData[correctPayAndConditionsIndex] : {}
  const displayDontKnowLevelTooltip = currentPayAndConditions?.payAndConditionsParentId && !hasSelectedSalary
    ? nationalPayrates.includes(currentPayAndConditions.payAndConditionsParentId)
    : false

  const renderRate = () => {
    if (hasSelectedSalary) {
      return (
        <ReviewSalaryContainer>
          <div style={{ marginBottom: '1.25rem' }}>
            <h3>Salary Employees</h3>
            <p style={{ lineHeight: '1.5rem' }}>If you&apos;re a salaried employee then your rates of pay are determined by your individual contract.</p>
            <p style={{ lineHeight: '1.5rem' }}>The SDA can help any member who is a salaried employee with information and advice about your pay and your rights at work.</p>
            <p style={{ lineHeight: '1.5rem' }}>
              Please <a href={askQuestionLink}>get in touch</a> with us for specific information.
            </p>
          </div>
        </ReviewSalaryContainer>
      )
    }
    if(wageTableOverrideUrl) {
      return (
        <ReviewSalaryContainer>
          <div>
            <p>
              <h3>Rates of pay at your workplace can be downloaded below.</h3>
            </p>
            <p>
              If you can&apos;t find your specific pay rate or need further information about your wages, please <ContactLink href={askQuestionLink}>contact us.</ContactLink>
            </p>
          </div>
          <ViewAllLink
            href={wageTableOverrideUrl}
            target="_blank"
            onClick={handleDownloadClick}
          >
            <FontAwesomeIcon
              icon={{
                iconName: 'table',
                prefix: 'far'
              }}
            />Download
          </ViewAllLink>
        </ReviewSalaryContainer>
      )
    }
    if (!payRate) return null
    return Object.keys(payRate).map(key => {
      const payRateLabel = tierLabels?.[Number(key)]
      return (
        <Tag key={key}>
          <Text size="small2x" color="info" transform="uppercase">
            {payRateLabel}
          </Text>
          <Text color="primary">{payRate[key as keyof IPayRate]}</Text>
        </Tag>
      )
    })
  }

  const showLevelInfo = () => {
    if (hasSelectedSalary) {
      setShowSalaryModal(true)
    } else {
      setShowTabsModal(true)
    }
  }

  const renderTabs = () => {
    return levelsInfo.map((level, index) => {
      return (
        <Tab label={level.title} key={index}>
          <h3>Classification level {index + 1}</h3>
          <div dangerouslySetInnerHTML={{ __html: `<strong>Job titles may include: </strong>${level.description}` }}></div>
        </Tab>
      )
    })
  }

  const renderAccordion = () => {
    const data = levelsInfo.map((level, index) => {
      return {
        title: 'Classification level ' + (index + 1),
        column1: `<strong style="font-size: 0.9rem;">Job titles may include: </strong>${level.description}`
      }
    })

    return (
      <AccordionSection accordion={data} />
    )
  }

  return (
    <PrimaryGenericCard headerTitle="Your minimum pay rate should be:" backgroundColor={showPayRate ? '#F3F8FC ' : '#FFF'}>
      <EmployerDataLoader />
      <FlexBox>
        {
          (status === Status.LOADING || payAndConditionsLoadState === Status.LOADING) ? (
              loadingSkeleton
            ) :
            <>
              {hasNoPayrate ? (
                <PayRateInfo isShow>
                  <StyledNoPayrate>
                    <p>
                      The SDA is here to help you understand your pay, your rights and entitlements.
                    </p>
                    <p>
                      <a href={askQuestionLink}>Get in touch with us if you need information, support or advice.</a>
                    </p>
                  </StyledNoPayrate>
                </PayRateInfo>
              ) : (
                <PayRateControl isShow={!showPayRate} className={payRateIndicatorState === 'settled' ? 'reset-fade' : ''}>
                  <GridItem as={DropdownTitle} order={1}>
                    Employment status <MackRequire>*</MackRequire>
                  </GridItem>
                  <GridItem order={3}>
                    <SelectDropdown
                      data={employStatus}
                      onChange={(val) => dispatch(setEmployStatusSelected(val))}
                      value={employStatusSelected?.[currentEmployerId]}
                      contentDefault="Select status"
                      wider
                    />
                  </GridItem>
                  {
                    !hasSelectedSalary ?
                    <GridItem as={DropdownTitle} order={2}>
                      Your level <MackRequire>*</MackRequire>
                    </GridItem> : <GridItem order={2}></GridItem>
                  }
                  {
                    !hasSelectedSalary &&
                    <GridItem order={4}>
                      <SelectDropdown
                        data={levelData}
                        onChange={(val) => dispatch(setRetailValueSelected(val))}
                        value={retailValueSelected?.[currentEmployerId]}
                        contentDefault="Select level"
                        wider
                        mini
                      />
                    </GridItem>
                  }
                  {displayDontKnowLevelTooltip &&
                      <GridItem as={InfoText} order={5} columnStart={2} columnEnd={2}
                                onClick={showLevelInfo}
                      >
                          <InfoIcon>
                              <FontAwesomeIcon
                                  icon={{
                                    iconName: 'info',
                                    prefix: 'fas'
                                  }}
                              />
                          </InfoIcon>
                          I don&apos;t know my level
                      </GridItem>
                  }
                  <GridItem order={6} span={2} $marginTop={!displayDontKnowLevelTooltip}>
                    <Button
                      disabled={submitIsDisabled}
                      onClick={() => {
                        setShowPayRate(true)
                        dispatch(setEmploymentLevel({ newLevel: retailValueSelected?.[currentEmployerId], newEmploymentStatus: employStatusSelected?.[currentEmployerId] }))
                        dispatch(pushEmploymentStatusUpdate({ newLevel: retailValueSelected?.[currentEmployerId], newEmploymentStatus: employStatusSelected?.[currentEmployerId]?.toLowerCase(), currentEmployerId: currentEmployerId}))
                      }}
                    >
                      Find out pay rate
                    </Button>
                  </GridItem>
                </PayRateControl>
              )}
              {(showPayRate && (payRate || hasSelectedSalary)) && (
                <PayRateInfo isShow={!hasNoPayrate && showPayRate}>
                  {renderRate()}
                </PayRateInfo>
              )}
            </>
        }
        {hasNoPayrate ? null : (
          <CardFooter>
            {userAgeIsUnknown && showPayRate && !wageTableOverrideUrl && !hasSelectedSalary &&
                <PleaseUpdateMessage>
                    Please <a href={`/${getActiveBranchFromSession(session)?.toLowerCase()}/update-your-details/`}>update your date of birth</a> to receive a more accurate pay rate estimate.
                </PleaseUpdateMessage>
            }
            {showPayRate && !wageTableOverrideUrl && !hasSelectedSalary &&
                <PleaseUpdateMessage>
                    Pay rate looks incorrect? Please <a href={askQuestionLink}>contact us.</a>
                </PleaseUpdateMessage>
            }
            {fullWageTable && !wageTableOverrideUrl &&
                <ViewAllLink
                    href={fullWageTable}
                    target="_blank"
                >
                    <FontAwesomeIcon
                        icon={{
                          iconName: 'table',
                          prefix: 'far'
                        }}
                    />View full wage table
                </ViewAllLink>
            }
          </CardFooter>
        )}
      </FlexBox>

      {/* Tabs Modal */}
      <Modal
        onClose={() => setShowTabsModal(false)}
        show={showTabsModal}
        title={(isMobile && 'I don\'t know my level') || undefined}
        backgroundColor={(isMobile && '#FFF') || undefined}
        mobileFullHeight={true}
      >
        {
          isMobile ? renderAccordion() : (
            <Tabs fullHeightParent={true}>
              {renderTabs()}
            </Tabs>
          )
        }
      </Modal>

      {/* Salary Modal */}
      <Modal
        onClose={() => setShowSalaryModal(false)}
        show={showSalaryModal}
        title={'As a salary worker your pay rate...'}
      >
        <SalaryPopupContent>
          <div>
            <p>
              Should be at least the minimum payrate outlined in your contract.
              As a salaried worker you are entitled to get paid for all hours worked.
              As a result of this, employees are often underpaid without realising it.
              If you don&apos;t think you&apos;re getting paid correctly, we can review this for you.
            </p>
            <h3>Submit your salary for review</h3>
            <p>Submit your salary to our case managers for review by sending us a copy of the following documents to
              <a href='mailto: salaryreview@sda.com.au'>salaryreview@sda.com.au</a>.
            </p>
            <ul>
              <li>Employment contract</li>
              <li>At least one recent payslip</li>
              <li>A copy of your roster</li>
            </ul>
          </div>
        </SalaryPopupContent>
      </Modal>
    </PrimaryGenericCard>
  )
}

export default PayRateIndicator
